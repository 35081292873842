.giam--multiline-field {
  .more-items {
    margin-top: 6px;
  }
}

.giam--multiline-field-modal {
  .data-item-list {
    list-style: none;
    padding-left: 0;
  }

  .data-item {
    padding: 10px 0;

    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }
  }
}
